import { render, staticRenderFns } from "./Permissions.vue?vue&type=template&id=19e416ae&scoped=true&"
import script from "./Permissions.vue?vue&type=script&lang=js&"
export * from "./Permissions.vue?vue&type=script&lang=js&"
import style0 from "./Permissions.vue?vue&type=style&index=0&id=19e416ae&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19e416ae",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/1/s/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('19e416ae')) {
      api.createRecord('19e416ae', component.options)
    } else {
      api.reload('19e416ae', component.options)
    }
    module.hot.accept("./Permissions.vue?vue&type=template&id=19e416ae&scoped=true&", function () {
      api.rerender('19e416ae', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pages/round/Permissions.vue"
export default component.exports