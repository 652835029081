import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "min-height": "100vh" }, attrs: { id: "rounds-bg" } },
    [
      _c("div", { attrs: { id: "rounds-content" } }, [
        _c(
          "a",
          {
            staticClass: "round-permissions-header-back-page",
            on: {
              click: function ($event) {
                return _vm.$router.push("/round/" + _vm.roundId)
              },
            },
          },
          [
            _c("font-awesome-icon", {
              staticClass: "round-permissions-header-back-page-icon",
              attrs: { icon: "fa-solid fa-arrow-left" },
            }),
            _c(
              "span",
              { staticClass: "round-permissions-header-back-page-text" },
              [_vm._v(_vm._s(_vm.$t("round_access_permissions")))]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "rounds-header" }, [
          _c("div", [
            _c("h2", { staticClass: "rounds-sub-title" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("round_access_permissions_subtitle")) + " "
              ),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "rounds-filter" },
          [
            _c("h3", { staticClass: "rounds-filters-title" }, [
              _vm._v(" " + _vm._s(_vm.$t("filters")) + " "),
            ]),
            _c(
              VForm,
              {
                staticClass: "rounds-filters-items-flex",
                on: {
                  submit: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.getRoundsByPage($event)
                  },
                },
              },
              [
                _c(
                  "div",
                  [
                    _c("span", { staticClass: "rounds-filters-label" }, [
                      _vm._v(_vm._s(_vm.$t("name"))),
                    ]),
                    _c(VTextField, {
                      staticClass: "closing-form-input-text",
                      attrs: { color: "primary", dense: "", outlined: "" },
                      model: {
                        value: _vm.filters.nameFilter,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "nameFilter", $$v)
                        },
                        expression: "filters.nameFilter",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("span", { staticClass: "rounds-filters-label" }, [
                      _vm._v(_vm._s(_vm.$t("order_by"))),
                    ]),
                    _c(VAutocomplete, {
                      staticClass: "rounds-filters-auto-complete",
                      attrs: {
                        items: _vm.permissionsOrderFields,
                        "item-text": _vm.translateField,
                        "item-value": "value",
                        color: "primary",
                        dense: "",
                        label: _vm.$t("select_label"),
                        outlined: "",
                      },
                      model: {
                        value: _vm.filters.Order,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "Order", $$v)
                        },
                        expression: "filters.Order",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "round-permission-check" },
                  [
                    _c(VCheckbox, {
                      staticClass: "field",
                      attrs: { color: "primary", outlined: "", dense: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c(
                                "span",
                                { staticStyle: { color: "var(--dark)" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("round_show_users_with_permission")
                                    )
                                  ),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.filters.AllowedOnly,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "AllowedOnly", $$v)
                        },
                        expression: "filters.AllowedOnly",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "rounds-filters-item-button-to-filter-box" },
                  [
                    _c("NewDxaButton", {
                      attrs: {
                        loading: _vm.loading,
                        type: "submit",
                        outline: true,
                        title: _vm.$tc("filter_kpi", 1),
                      },
                      on: { btnFunction: _vm.getFilteredPermissions },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
        _vm.loading
          ? _c(
              "div",
              { staticClass: "box-loading" },
              [
                _c(VProgressCircular, {
                  attrs: { indeterminate: "", size: "35", color: "primary" },
                }),
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "rounds-table-content" },
              [
                _c(
                  VTabs,
                  {
                    staticClass: "rounds-table-tabs",
                    on: { change: _vm.tabChanged },
                    model: {
                      value: _vm.currentTab,
                      callback: function ($$v) {
                        _vm.currentTab = $$v
                      },
                      expression: "currentTab",
                    },
                  },
                  [
                    _c(VTab, [_vm._v(_vm._s(_vm.$tc("investor", 2)))]),
                    _c(VTab, [_vm._v(_vm._s(_vm.$tc("partner", 2)))]),
                  ],
                  1
                ),
                _c(VSimpleTable, [
                  _c(
                    "thead",
                    { staticClass: "rounds-table-header rounds-table-head" },
                    [
                      _c(
                        "th",
                        [
                          _vm._v(" " + _vm._s(_vm.$t("name")) + " "),
                          _c(
                            VIcon,
                            {
                              attrs: { small: "", color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.headerClick(
                                    _vm.orderFilterEnum.NAME
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.orderByMode === _vm.orderByModeEnum.ASC
                                    ? "mdi-arrow-up"
                                    : "mdi-arrow-down"
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm.currentTab === _vm.tabPermissionEnum.INVESTOR
                        ? _c(
                            "th",
                            [
                              _vm._v(" " + _vm._s(_vm.$t("email")) + " "),
                              _c(
                                VIcon,
                                {
                                  attrs: { small: "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.headerClick(
                                        _vm.orderFilterEnum.EMAIL
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.orderByMode ===
                                        _vm.orderByModeEnum.ASC
                                        ? "mdi-arrow-up"
                                        : "mdi-arrow-down"
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("th", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("briefing_permission")) + " "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "tbody",
                    _vm._l(_vm.permissions, function (item, i) {
                      return _c("tr", { key: i }, [
                        _c("td", { staticClass: "text-center" }, [
                          item.fullName
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "rounds-table-item rounds-table-item-round-number",
                                },
                                [_vm._v(_vm._s(item.fullName) + " ")]
                              )
                            : _c("p", [_vm._v("-")]),
                        ]),
                        item.email
                          ? _c("td", { staticClass: "text-center" }, [
                              _vm.currentTab === _vm.tabPermissionEnum.INVESTOR
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "rounds-table-item rounds-table-item-company-name",
                                    },
                                    [_vm._v(_vm._s(item.email) + " ")]
                                  )
                                : _c("span", [_vm._v("-")]),
                            ])
                          : _vm._e(),
                        _c(
                          "td",
                          {
                            staticClass: "text-start",
                            staticStyle: {
                              display: "flex",
                              "justify-content": "center",
                            },
                          },
                          [
                            _c(VCheckbox, {
                              staticClass: "field",
                              attrs: {
                                color: "primary",
                                outlined: "",
                                dense: "",
                              },
                              on: {
                                change: function (e) {
                                  return _vm.toggleUserPermission(e, item)
                                },
                              },
                              model: {
                                value: item.briefingPermission,
                                callback: function ($$v) {
                                  _vm.$set(item, "briefingPermission", $$v)
                                },
                                expression: "item.briefingPermission",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    }),
                    0
                  ),
                ]),
                _c(VPagination, {
                  attrs: { color: "primary", length: _vm.pageCount },
                  on: { input: _vm.changePage },
                  model: {
                    value: _vm.page,
                    callback: function ($$v) {
                      _vm.page = $$v
                    },
                    expression: "page",
                  },
                }),
              ],
              1
            ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }